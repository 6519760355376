import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function ProtectedRoute({ isAuth, user, component: Component, ...rest}){
   
    return (
        <Route 
            {...rest}
            render={(props) => {
                if(isAuth)
                {
                    return <Component isAuth={isAuth} id={props.match.params.id}/>;
                }
                else{
                    return (
                        <Redirect to={{ pathname: '/login', state: {from: props.location}}} />
                    );
                }
            }}
        />
    );
}

export default ProtectedRoute;