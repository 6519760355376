import { combineReducers } from "redux"
import cartReducer from "./cartReducer"
import globalReducer from "./globalReducer"
import filterReducer from "./filterReducer"
import productReducer from "./productReducer"

const rootReducer = combineReducers({
  cartdata: cartReducer,
  globaldata: globalReducer,
  productdata: productReducer,
  filterReducer: filterReducer,
})
export default rootReducer