import React,{useState, useEffect} from 'react';
import {Link, Redirect} from 'react-router-dom';
import { AuthService } from '../../../services/auth-service';
import { useDispatch, useSelector } from 'react-redux';


const Header=(props)=>{
  
  const [logo,setLogo]=useState(null)
  const [template_status,setTemplate_status]=useState(0)

  const dataCounter=useSelector(state=>state.productdata.cartCounter)
  
  const cart=useSelector(state=>state.cartdata.cart)
  const dispatch=useDispatch()
  const cartLength=JSON.parse(!localStorage['cart'] ? localStorage.getItem('dataCart'):localStorage.getItem('cart'))  
 
  const headerfetch=async ()=>{
    let formdata=new FormData()
      formdata.append("vendor_id",window.config.vendor_id)
      
    const response = await AuthService.header(formdata)
    if(response.data.success) {
      if(response.data.is_website_completed === 1) {
  
              setLogo(response.data.logo);
              localStorage.setItem('logo', response.data.logo);
      
               setTemplate_status(1)   
              localStorage.setItem('template_status', response.data.is_website_completed);
            }
            else {
              localStorage.setItem('template_status', response.data.is_website_completed);
            }
            
    }
    // .then(res=>{
    //   if(res.data.success === true)
    //   {
    //     if(res.data.is_website_completed === 1) {
  
    //       setLogo(res.data.logo);
    //       localStorage.setItem('logo', res.data.logo);
  
    //        setTemplate_status(1)   
    //       localStorage.setItem('template_status', res.data.is_website_completed);
    //     }
    //     else {
    //       localStorage.setItem('template_status', res.data.is_website_completed);
    //     }
    //   }

    // })
   


  }



const handleChange=()=> {
  window.scrollTo(0, 0);
}


const handleAboutClick=(e)=> {
  
  // window.scroll({top: document.body.offsetHeight, left: 0, behavior: 'smooth', } )
  window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
 
  
}

const handleLogout = (e) => {
  e.preventDefault()

  localStorage.removeItem('token')
  localStorage.removeItem('user_id')
  localStorage.removeItem('user_type')
  

  props.setUser(null)
  return <Redirect to="/" />
}
useEffect(()=>{
  
  
  headerfetch()
 


},[])


let buttons;
if(props.isAuth)
{
    buttons = (
        <>
        <li className="text-uppercase"><Link to="#" style={{ textDecoration: 'none'}} onClick={handleLogout} className="link-tag">Logout</Link></li>
        </>
    )
}
else{
    buttons = (
        <>
        <li className='text-uppercase'><Link to='/login' className="link-tag">Login</Link></li>
        
        </>
    )
}

let styles

if (logo === null) {
  styles = { maxHeight: "90px", display: "none" }
}
else {
  styles = { maxHeight: "90px" }
}


  return (
    
    <header className="header position-fixed w-100" id="header">
    <div className="container">
      <nav className="navbar navbar-expand-md px-0">
        <div className="align-items-center d-flex justify-content-between toggle-bar">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
          <span className="fa fa-bars navbar-toggler-icon"></span>
        </button>
        <div className='d-md-none mob-logo'>
        {
            logo  
            ? <Link to={'/'}><img src={logo} alt="logo"  className="img-fluid" /></Link>
            : ''
          }
        
        </div>

        </div>

        <div className="collapse navbar-collapse text-center " id="navbarTogglerDemo03">
        
          {
            logo  
            ? <Link to={'/'}><img src={logo} alt="logo" className='d-md-block d-none' style={styles} id="logo" /></Link>
            : ''
          }
        
          <ul className="navbar-nav  menuCenter mr-auto mt-md-2 mt-lg-0">
            {template_status === 1 ? 
              <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                <li className="nav-item active">
                  <Link  to="/" onClick={handleChange}>home</Link>
                </li>
                <li className="nav-item active">
                  <Link to="/products" className="nav-link" >OUR SHOWCASE</Link>
                </li>
                <li className="nav-item text-uppercase">
                  <Link 
                    to={{pathname:"/",hash:"#about"}}
                     
                    onClick={handleAboutClick}
                    
                  >About Us</Link>
                </li>
              </ul>
              : props.isAuth 
              ? <li className="nav-item">
                <Link className="nav-link" to="/products">OUR SHOWCASE</Link>
              </li>
              : ''
            }

            {/* { this.props.isAuth &&
              
              <li className="nav-item">
                <Link className="nav-link" to="/products">GEMSTONES</Link>
              </li>
            } */}
          </ul>

          <div className="login">
            {/* <a href="#">login</a> */}
            <ul >
              {
              props.isAuth &&  
                 <li className="text-uppercase"> 
                   {/* <i className="fa fa-search"></i> */}
                   <Link style={{ textDecoration: 'none'}} to='/cart' className="nav-item ml-2"><i className="fa fa-shopping-basket cart-icon"> 
                     {/* <span className="cart-count"></span>  */}
                     </i > My Cart ({JSON.parse(!localStorage['cart'] ? localStorage.getItem('dataCart'):localStorage.getItem('cart')).length}) </Link> 
                 </li> 
              
               
              }
              {buttons}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </header>
   
  )
}

export default Header   

    
   
