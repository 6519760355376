import swal from 'sweetalert';
import { toast } from 'react-toastify'
import { APPLY_FILTER, CLEAR_FILTER, CLEAR_FILTER_DOM_VALUES, SAVE_FILTER_DOM_VALUES } from "../constant"

export const applyFilter = (filterObj) => {
  return (dispatch) => {
    localStorage.setItem('filters', JSON.stringify(filterObj))

    dispatch({
      type: APPLY_FILTER,
      payload: filterObj
    })
    // toast('Product added to cart Successfully',{
    //     className: 'success-toast',
    //     draggable: false,
    //     position: toast.POSITION.TOP_RIGHT
    // })
  }
}

export const clearFilter = () => {
  return (dispatch) => {
    localStorage.setItem('filters', JSON.stringify({}))

    dispatch({
      type: "CLEAR_FILTER"
    })
  }
}
export const saveFilterDOMValues = (filterDOMObj) => {
  return (dispatch) => {
    localStorage.setItem('refValues', JSON.stringify(filterDOMObj))

    dispatch({
      type: SAVE_FILTER_DOM_VALUES,
      payload: filterDOMObj
    })
  }
}

export const clearFilterDOMValues = () => {
  return (dispatch) => {
    localStorage.setItem('refValues', JSON.stringify({}))

    dispatch({
      type: CLEAR_FILTER_DOM_VALUES
    })
  }
}