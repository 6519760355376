import React from 'react';

const Loader = () => (
    <div className="row loader_div">
        <div className="d-flex align-items-center justify-content-center h-100">
            <div className="d-flex flex-column text-center">
                <h6 >Please wait</h6>
                <p>
                    <img 
                        src='/images/waiting.gif' 
                        alt="Loader" 
                        className="justify-content-center align-items-center" 
                        width="50%" 
                    />
                </p>
            </div>
        </div>
    </div>
)

export default Loader;