import '../assets/css/ProductDetail.css'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from '../components/Layout/common/Loader'
import NumberFormat from 'react-number-format'
import Table from 'react-bootstrap/Table'
import ProductMedia from '../components/Products/ProductMedia'
import { ProductService } from '../services/products-service'
import { useDispatch, connect } from 'react-redux'
import { add_to_cart } from '../store/actions/cart.action'
import { setloadingfalse, setloadingtrue } from '../store/actions/global.action'
import { incrementData } from '../store/actions/products.action'

const ProductDetail = props => {
	// ======================================================= States / Context =======================================================

	const [item, setItem] = useState(null)
	const [loading, setLoading] = useState(false)

	const dispatch = useDispatch()
	const { cartData } = props

	// ======================================================= Function Declarations =======================================================
	const formatVideoURL = vidLink => {
		let subStringG = 'drive.google.com/file/d'
		let containsG = '/view'
		let splitG = vidLink.split('/')
		let valueToReplaceG = splitG[splitG.length - 1]

		let subStringY = 'youtube.com/'
		let containsY = '/watch?v='
		let containsYShorts = '/shorts'
		let splitY = vidLink.split('/watch?v=')
		let splitYShorts = vidLink.split('/shorts')

		let subStringV = 'vimeo.com'
		let containsV = 'video'
		let splitV = vidLink.split('/')
		let valueToReplaceV = splitV.filter(e => /^\d+$/.test(e) === true)[0]

		if (vidLink.includes(subStringG) && vidLink.includes(containsG)) {
			vidLink = vidLink.replace(valueToReplaceG, 'preview')
			let formattedLink = `https://drive.google.com/file/d/${splitG[5]}/preview`
			return formattedLink
		} else if (vidLink.includes(subStringY) && vidLink.includes(containsY)) {
			let a = splitY[1].split('&')[0]
			let formattedLink = `https://www.youtube.com/embed/${a}`
			return formattedLink
		} else if (
			vidLink.includes(subStringY) &&
			vidLink.includes(containsYShorts)
		) {
			let a = splitYShorts[1].split('&')[0]
			let formattedLink = `https://www.youtube.com/embed/${a}`

			return formattedLink
		} else if (vidLink.includes(subStringV)) {
			let a = splitV[1].split('&')[0]
			let formattedLink = `https://player.vimeo.com/video/${valueToReplaceV}`
			return formattedLink
		} else {
			return vidLink
		}
	}

	const getProduct = async () => {
		dispatch(setloadingtrue())

		const reqData = {
			user_id: localStorage.getItem('user_id'),
			user_type: localStorage.getItem('user_type'),
			parcel_type_id: '1',
			stone_detail_id: props.id,
		}

		await ProductService.productPages(reqData).then(res => {
			if (res) {
				let allLinks = res.data.data_array[0].video_link
				allLinks.forEach((url, i) => {
					const split = formatVideoURL(url.link)
					url.link = split
				})

				setItem(res.data.data_array[0])

				dispatch(setloadingfalse())
			} else {
				dispatch(setloadingfalse())
			}
		})
	}

	// ======================================================= Use Effects =======================================================
	useEffect(() => {
		window.$('#caratSlider').slider({})
		window.$('#priceSlider').slider({})
		getProduct()

		const localcart = JSON.parse(
			localStorage.getItem('cart') === null
				? localStorage.getItem('dataCart')
				: localStorage.getItem('cart')
		)

		if (localcart !== null) {
			if (localcart.length !== 0 && cartData.length === 0) {
				for (let i = 0; i < localcart.length; i++) {
					dispatch(add_to_cart(localcart[i]))
				}
			}
		}
	}, [])

	return (
		<div className='main_content mainWrapper'>
			{loading ? (
				<Loader />
			) : (
				<section className='detail-sec-1 pt-1 w-100 container'>
					{/* Back Button */}
					<div className='row'>
						<div className='col-12'>
							<div className='pull-right mr-3'>
								<Link to='/products' className='box-anchor back-btn'>
									<button
										className='custom-btn mt-3 mb-2'
										style={{ width: '60px', borderRadius: '0px' }}
									>
										Back
									</button>
								</Link>
							</div>
						</div>
					</div>

					{/* Product Content */}
					{item ? (
						<div className='container pb-5'>
							<div className='row'>
								<div className='col-md-7 col-12'>
									<div className='align-items-center d-md-flex'>
										<ProductMedia item={item} />
									</div>
								</div>
								{/* Product Text Content */}

								<div className='col-md-5 col-sm-12 col-12 pt-lg-0 pt-4'>
									<h4 className='mb-2'>
										{item &&
										item.internal_reference_number !== '' &&
										item.internal_reference_number !== undefined ? (
											<span>Ref # : {item.internal_reference_number}</span>
										) : (
											<span>Ref # : {item.system_reference_number}</span>
										)}
									</h4>
									<p className='mb-2'>
										{item
											? `${item.stone_types}${
													!item.stone_types && !item.weight
														? ' '
														: item.stone_types && !item.weight
														? ' '
														: !item.stone_types && item.weight
														? ' '
														: ', '
											  }`
											: ' '}
										{item ? `${item.weight} ` : ''}
										{item
											? `${item.weight_type}${
													!item.weight_type && !item.origin
														? ' '
														: item.weight_type && !item.origin
														? ' '
														: !item.weight_type && item.origin
														? ' '
														: ', '
											  }`
											: ''}
										{item ? item.origin : ''}
									</p>

									<p className='mb-2'>
										<b>
											Total Price :{' '}
											<NumberFormat
												value={item.custome_price_total}
												displayType={'text'}
												thousandSeparator={true}
												prefix={item.custome_price_currency}
												decimalScale={2}
												fixedDecimalScale={true}
											/>
										</b>
									</p>
									<p className='mb-2'>
										<b>
											Price / {item.weight_type} :{' '}
											<NumberFormat
												decimalScale={2}
												displayType={'text'}
												thousandSeparator={true}
												fixedDecimalScale={true}
												value={item.custome_price === 0 ? item.selling_price_per_carat : item.custome_price}
												prefix={item.custome_price_currency}
											/>
										</b>
									</p>
									<div className='mt-1'>
										<div className='stone-detail-descrip'>
											<div className='gemstone-detail p-0'>
												<p className='mb-2'>
													<b>Gemstone Full Detail</b>
												</p>
												<table className='gemstone-detail-table'>
													<tbody>
														<tr>
															<td>Parcel Type</td>
															<td>
																{item.parcel_types !== ''
																	? item.parcel_types
																	: '--'}
															</td>
														</tr>
														<tr>
															<td>Reference No.</td>
															{item &&
															item.internal_reference_number !== '' &&
															item.internal_reference_number !== undefined ? (
																<td>{item.internal_reference_number}</td>
															) : (
																<td>{item.system_reference_number}</td>
															)}
															{/* <td>{item.system_reference_number}</td> */}
														</tr>
														<tr>
															<td>Gemstone Type</td>
															<td>
																{item.stone_types !== ''
																	? item.stone_types
																	: '--'}
															</td>
														</tr>
														<tr>
															<td>Shape</td>
															<td>{item.shapes !== '' ? item.shapes : '--'}</td>
														</tr>
														<tr>
															<td>Color</td>
															<td>{item.color !== '' ? item.color : '--'}</td>
														</tr>
														<tr>
															<td>Grade</td>
															<td>
																{item.color_grade !== ''
																	? item.color_grade
																	: '--'}
															</td>
														</tr>
														<tr>
															<td>Treatment</td>
															<td>
																{item.treatment !== '' ? item.treatment : '--'}
															</td>
														</tr>
														<tr>
															<td>Origin</td>
															<td>{item.origin !== '' ? item.origin : '--'}</td>
														</tr>
														<tr>
															<td>Dimensions</td>
															<td>
																{item.measurement !== ''
																	? item.measurement
																	: '--'}
															</td>
														</tr>
														<tr>
															<td>Weight in {item.weight_type}</td>
															<td>{item.weight !== '' ? item.weight : '--'}</td>
														</tr>
													</tbody>
												</table>
											</div>
											{item.certificate.length !== 0 && (
												<div className='mt-4'>
													<h4>Lab Report</h4>
													<Table striped bordered hover>
														<thead>
															<tr>
																<th>Lab Report</th>
																<th>Lab Report Number</th>
																<th>Lab Report URL</th>
																<th>Lab Report File</th>
															</tr>
														</thead>
														<tbody>
															{
																// lab_reports
																item.certificate.length ? (
																	item.certificate.map(rec => {
																		return (
																			<tr key={rec.certificate_name}>
																				<td>{rec.certificate_name}</td>
																				<td>{rec.certificate_number}</td>
																				{rec.certificate_URL === '' ||
																				rec.certificate_URL === null ? (
																					<td className='text-center'>--</td>
																				) : (
																					<td>
																						<a
																							href={rec.certificate_URL}
																							className='text-dark'
																							rel='noopener noreferrer'
																							target='_blank'
																						>
																							Lab Report Link
																						</a>
																					</td>
																				)}
																				{rec.certificate_file === '' ||
																				rec.certificate_file === null ? (
																					<td className='text-center'>--</td>
																				) : (
																					<td>
																						<a
																							download
																							href={rec.certificate_file}
																							className='text-dark fw-bolder'
																							rel='noopener noreferrer'
																							target='_blank'
																						>
																							Download File
																						</a>
																					</td>
																				)}
																			</tr>
																		)
																	})
																) : (
																	<tr>
																		{' '}
																		<td colSpan='4'>No Certificate</td>{' '}
																	</tr>
																)
															}
														</tbody>
													</Table>
												</div>
											)}

											<button
												className='custom-btn cart-btn mt-4'
												onClick={() => {
													if (
														!cartData.find(cartitem => cartitem.id === item.id)
													) {
														dispatch(add_to_cart(item))
														dispatch(incrementData())
														let arr = []
														arr = JSON.parse(
															localStorage.getItem('cart') === null
																? localStorage.getItem('dataCart')
																: localStorage.getItem('cart')
														)
														arr.push(item)
														localStorage.setItem('cart', JSON.stringify(arr))
														localStorage.setItem(
															'dataCart',
															JSON.stringify(arr)
														)

														toast('Product added to cart Successfully', {
															className: 'success-toast',
															draggable: false,
															position: toast.POSITION.TOP_RIGHT,
														})
													} else {
														toast.error('Product already added to cart !!!', {
															className: 'danger-toast',
															draggable: false,
															position: toast.POSITION.TOP_RIGHT,
														})
													}
												}}
											>
												{cartData.every(cartItem => {
													return cartItem.id !== item.id
												})
													? 'Add To Cart'
													: 'Added to Cart'}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (
						''
					)}
				</section>
			)}
		</div>
	)
}

const mapStateToProps = state => {
	return {
		cartData: state.cartdata.cart,
	}
}
export default connect(mapStateToProps)(ProductDetail)
