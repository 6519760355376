import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import 'react-toastify/dist/ReactToastify.css'
import { Link } from 'react-router-dom'
import {
	loadCaptchaEnginge,
	LoadCanvasTemplate,
	validateCaptcha,
} from 'react-simple-captcha'
import { AuthService } from '../../services/auth-service'
import { toast } from 'react-toastify'
import api from '../../services/API'

export default function Login(props) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [loading, setLoading] = useState(false)
	const [template_status, setTemplate_status] = useState(0)
	const [vendor_id, setVendor_id] = useState(
		window ? window.config.vendor_id : ''
	)

	useEffect(() => {
		loadCaptchaEnginge(6, 'white', 'black')

		localStorage.removeItem('filters')
		localStorage.removeItem('refValues')
	}, [])

	const login = async e => {
		e.preventDefault()

		let user_captcha = document.getElementById('user_captcha_input').value
		const msg = (
			<p className='toastMessage'>
				Invalid e-mail address or password, If you still cannot get in after
				multiple tries, please <Link to='/toasttest'>contact us</Link> for new
				credentials.
			</p>
		)

		if (validateCaptcha(user_captcha) === true) {
			setLoading(true)

			await AuthService.userLogin(email, password)
				.then(res => {
					if (res.data.success === true) {
						setLoading(false)
						api.defaults.headers['Authorization'] = `Bearer ${res.data.token}`
						if (res.data.is_website_completed === 1) {
							localStorage.setItem('token', res.data.token)
							localStorage.setItem('user_id', res.data.customer.id)
							localStorage.setItem('user_type', res.data.user_type)
							localStorage.setItem('filters', JSON.stringify({}))
							localStorage.setItem('refValues', JSON.stringify({}))

							localStorage.setItem('hero_bg', res.data.hero_bg)
							localStorage.setItem('about_bg', res.data.about_bg)

							localStorage.setItem(
								'selection_stone_1_img',
								res.data.selection_stone_1_img
							)
							localStorage.setItem(
								'selection_stone_2_img',
								res.data.selection_stone_2_img
							)
							localStorage.setItem(
								'selection_stone_3_img',
								res.data.selection_stone_3_img
							)
							localStorage.setItem(
								'template_status',
								res.data.is_website_completed
							)
							if (!localStorage['cart']) {
								localStorage.setItem('cart', JSON.stringify([]))
							}
							if (!localStorage['dataCart']) {
								localStorage.setItem('dataCart', JSON.stringify([]))
							}

							setTemplate_status(1)
						} else {
							localStorage.setItem('token', res.data.token)
							localStorage.setItem('filters', JSON.stringify({}))
							localStorage.setItem('user_id', res.data.customer.id)
							localStorage.setItem('user_type', res.data.user_type)
							localStorage.setItem(
								'template_status',
								res.data.is_website_completed
							)
							localStorage.setItem('refValues', JSON.stringify({}))
							if (localStorage.getItem('cart') === 'null') {
								localStorage.setItem('cart', JSON.stringify([]))
							}
							if (localStorage.getItem('dataCart') === 'null') {
								localStorage.setItem('dataCart', JSON.stringify([]))
							}
						}

						props.handleLogin(res.data)
					} else {
						setLoading(false)
					}
				})
				.catch(err => {
					setLoading(false)
				})
		} else {
			toast.error('Captcha Does Not Match', {
				className: 'danger-toast',
				draggable: false,
				position: toast.POSITION.TOP_RIGHT,
			})
			setLoading(false)
			document.getElementById('user_captcha_input').value = ''
		}
	}

	function removeAttribute(e) {
		e.target.removeAttribute('readonly')
	}
	if (props.isAuth) {
		if (template_status === 1) {
			return <Redirect to={'/'} />
		} else {
			return <Redirect to={'/products'} />
		}
	} else {
		return (
			<div className='main_content mainWrapper'>
				{/* <ToastContainer /> */}
				<div className='container zindex'>
					<div className='text-center text-black p-2'>
						<h2 className='mx-auto'>Login</h2>
					</div>
					<div className='login-form tran-bg mx-auto'>
						<div className='custom-form'>
							<div className='form-group'>
								<label htmlFor='exampleInputEmail1'>Email address</label>
								<input
									type='email'
									className='form-control'
									name='email'
									id='exampleInputEmail1'
									onChange={e => setEmail(e.target.value)}
									aria-describedby='emailHelp'
									placeholder='Enter email'
								/>
							</div>

							<div className='form-group'>
								<label htmlFor='exampleInputPassword1'>Password</label>
								<input
									type='password'
									className='form-control'
									name='password'
									id='exampleInputPassword1'
									onChange={e => setPassword(e.target.value)}
									placeholder='Password'
								/>
							</div>

							<div className='mt-4'>
								<LoadCanvasTemplate id='user_captcha_input' />
								<div className='form-group mt-3'>
									<input
										placeholder='Enter Captcha Value'
										className='form-control'
										id='user_captcha_input'
										name='user_captcha_input'
										type='text'
										onClick={e => removeAttribute(e)}
										readOnly
									/>
								</div>
								<button
									type='button'
									onClick={e => login(e)}
									className='btn btn-primary'
									disabled={loading}
								>
									{loading && (
										<img
											src='/images/loader.gif'
											alt=''
											width='20px'
											className='mr-2'
										/>
									)}
									{loading && <span>Please Wait </span>}
									{!loading && <span>Login </span>}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
