import React from 'react';

const ContactUs = () => {
    
    return (
        <>
            {/* Contact us */}
            <div className="contact-us bg-cover text-center d-flex align-items-center py-5 contact-us"
             style={{background: "url("+localStorage.getItem('contact_bg')+")"}}>
                <div className="container py-4">
                    <div className="row">
                        <div className="col-md-4 mx-auto col-12 text-white contact-us-col">
                            <img src={localStorage.getItem('logo')} alt="logo"
                             className="contact-logo img-fluid mb-4 pb-2" />
                            <h3 className="mb-5">CONTACT US</h3>
                            <p>{localStorage.getItem('address')}<br/>
                                <a href={"mailto:"+localStorage.getItem('email')}>
                                    {localStorage.getItem('email')}</a><br/>
                                <a href={"tel:"+localStorage.getItem('phone')}>
                                    {localStorage.getItem('phone')}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs;