import api from "./API"

export const ContentMain={
    completedContent:async (data)=>{
        return await  api.post(`/get-stone-type-data`,data)
       
    },
    gemtypes:async (data)=>{
        return await api.post(`/get-stone-type`,data)
       
    }

}