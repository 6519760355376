import { FETCH_ALL_PRODUCTS, SORT_ALL_PRODUCTS } from "../constant";

const initState = {
  products: [],
  stonesData: [],
  cartCounter: 0

}
const productReducer = (state = initState, action) => {

  switch (action.type) {
    case FETCH_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload.data_array,
        stonesData: action.payload.stones,

      }
    case SORT_ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,

      }
    case "INCREMENT":
      return {
        ...state, cartCounter: state.cartCounter + 1
      }
    case "DECREMENT":
      return {
        ...state, cartCounter: state.cartCounter - 1
      }

    default:
      return state
  }

}
export default productReducer;