import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Loader from '../Layout/common/Loader'

import { AuthService } from '../../services/auth-service'

export default function AdminLogin(props) {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const [loading, setLoading] = useState(false)
	const [template_status, setTemplate_status] = useState(0)
	const [vendor_id, setVendor_id] = useState(
		window ? window.config.vendor_id : ''
	)

	const DirectLogin = async props => {
		setLoading(true)

		await AuthService.login().then(res => {
			if (res.data.success === true) {
				if (res.data.is_website_completed === 1) {
					localStorage.setItem('token', res.data.token)
					localStorage.setItem('user_id', res.data.user.id)
					localStorage.setItem('user_type', res.data.user_type)
					localStorage.setItem('filters', JSON.stringify({}))
					localStorage.setItem('refValues', JSON.stringify({}))

					localStorage.setItem('hero_bg', res.data.hero_bg)
					localStorage.setItem('about_bg', res.data.about_bg)

					localStorage.setItem(
						'selection_stone_1_img',
						res.data.selection_stone_1_img
					)
					localStorage.setItem(
						'selection_stone_2_img',
						res.data.selection_stone_2_img
					)
					localStorage.setItem(
						'selection_stone_3_img',
						res.data.selection_stone_3_img
					)
					localStorage.setItem('template_status', res.data.is_website_completed)
					let arr = []
					localStorage.setItem('cart', JSON.stringify(arr))
					localStorage.setItem('dataCart', JSON.stringify(arr))

					setTemplate_status(1)
					window.location = '/'
				} else {
					localStorage.setItem('token', res.data.token)
					localStorage.setItem('user_id', res.data.user.id)
					localStorage.setItem('filters', JSON.stringify({}))
					localStorage.setItem('user_type', res.data.user_type)
					localStorage.setItem('refValues', JSON.stringify({}))
					localStorage.setItem('template_status', res.data.is_website_completed)

					window.location = '/products'
				}

				props.handleLogin(res.data)
			} else {
				if (res.response.err.message) {
					toast('Invalid Credentials !!!', {
						className: 'error-toast',
						draggable: false,
						position: toast.POSITION.TOP_RIGHT,
					})
					setLoading(false)
				}
			}
		})
	}
	useEffect(() => {
		DirectLogin(props)
	}, [])

	return <div className='main_content'>{loading && <Loader />}</div>
}
