import React from 'react';
import { Link } from 'react-router-dom';

const OurCollection = () => {
    return(
        <>
            <section className="our-selection text-center" id="our-showcase">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12">
                            <Link to="/products" className="btn btn-default">SHOP NOW</Link>
                            <h2 className="text-uppercase title">OUR GEMSTONES</h2>
                        </div>
                        
                        <div className="align-items-center col-md-12">
                            <div className="row w-100">
                                <div className="col-md-4 col-sm-6 col-12 selection-prod-col">
                                    <Link to="/products">
                                        <img src={localStorage.getItem('selection_stone_1_img')}
                                         alt="" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12 selection-prod-col">
                                    <Link to="/products">
                                        <img src={localStorage.getItem('selection_stone_2_img')}
                                         alt="" className="img-fluid" />
                                    </Link>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12 selection-prod-col">
                                    <Link to="/products">
                                        <img src={localStorage.getItem('selection_stone_3_img')}
                                         alt="" className="img-fluid" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurCollection;