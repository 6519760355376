import React, {useState,useEffect } from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useSelector } from 'react-redux';

const Checkout=(props)=>{
    
    const [enable,setEnable]=useState(true)
    
    const cart=useSelector(state=>state.cartdata.cart)

    useEffect(()=>{
        localStorage.removeItem('filters')
        localStorage.removeItem('refValues')
    },[])

    return(
        
            
            <div className="main_content">
                    <Container className="checkout-section">
                        <Row>
                            <Col md={{ span: 6}}>
                                <h6 className="text-white mt-4">Billing Details</h6>
                                <div className="billing_details">
                                <Form>
                                    <Form.Group controlId="formGridName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" placeholder="Name" />
                                    </Form.Group>

                                    <Form.Group controlId="formGridEmail">
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" />
                                    </Form.Group>


                                    <Form.Group controlId="formGridAddress1">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control placeholder="1234 Main St" />
                                    </Form.Group>

                                    <Form.Group controlId="formGridAddress2">
                                        <Form.Label>Address 2</Form.Label>
                                        <Form.Control placeholder="Apartment, studio, or floor" />
                                    </Form.Group>

                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridState">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control as="select" defaultValue="Choose...">
                                            <option>Choose...</option>
                                            <option>...</option>
                                        </Form.Control>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridZip">
                                        <Form.Label>Zip</Form.Label>
                                        <Form.Control />
                                        </Form.Group>
                                    </Form.Row>
                                    </Form>
                                    <hr></hr>
                                    <Accordion>
                                        <Card style={{color: 'black'}}>
                                            <Card.Header className="p-0">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                { enable === true ? <span onClick={() => setEnable(!enable)}>Enable Shipping Details</span> : <span onClick={() => setEnable(!enable)}>Disable Shipping Details</span>}
                                            </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                            <Form>
                                                <Form.Group controlId="formGridName">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" placeholder="Name" />
                                                </Form.Group>

                                                <Form.Group controlId="formGridEmail">
                                                    <Form.Label>Email Address</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" />
                                                </Form.Group>


                                                <Form.Group controlId="formGridAddress1">
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control placeholder="1234 Main St" />
                                                </Form.Group>

                                                <Form.Group controlId="formGridAddress2">
                                                    <Form.Label>Address 2</Form.Label>
                                                    <Form.Control placeholder="Apartment, studio, or floor" />
                                                </Form.Group>

                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridCity">
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control />
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridState">
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control as="select" defaultValue="Choose...">
                                                        <option>Choose...</option>
                                                        <option>...</option>
                                                    </Form.Control>
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="formGridZip">
                                                    <Form.Label>Zip</Form.Label>
                                                    <Form.Control />
                                                    </Form.Group>
                                                </Form.Row>
                                                </Form>
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                    </div>
                            </Col>
                            <Col md={{span: 6}}>
                                <h6 className="text-white mt-4"><span className="fa fa-shopping-basket mr-1"></span>Cart Summary</h6>
                                {
                                    cart.map(item => (
                                        <div key={item.id} className="checkout-items">
                                            <Row>
                                            <Col md={{ span: 3}}>
                                            <img src={item.image} alt='' width="100px"/>
                                            </Col>
                                            <Col>
                                            <div className="checkout-products">
                                            <p>Product Code : {item.system_reference_number}</p>
                                            <p>Color : {item.color != null ? item.color : '--'}</p>
                                            <p>Price : <span className="price">{item.custome_price_currency}{item.total_selling_price}</span></p>
                                            </div>
                                            </Col>
                                            </Row>
                                            <hr></hr>
                                        </div>
                                    ))
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>

        
    )
}

export default Checkout;


    
    
 
        
      
            
     