export const INCREASE = 'INCREASE'
export const REDUCTION = 'REDUCTION'

export const APPLY_FILTER = "APPLY_FILTER"
export const CLEAR_FILTER = "CLEAR_FILTER"

export const SAVE_FILTER_DOM_VALUES = "SAVE_FILTER_DOM_VALUES"
export const CLEAR_FILTER_DOM_VALUES = "CLEAR_FILTER_DOM_VALUES"

export const CLEAR_CART = "CLEAR_CART"
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'

export const FETCH_PRODUCTS = 'FETCH_PRODUCTS'
export const FETCH_ALL_PRODUCTS = "FETCH_ALL_PRODUCTS"

export const SORT_ALL_PRODUCTS = "SORT_ALL_PRODUCTS"