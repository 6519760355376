import { APPLY_FILTER, CLEAR_FILTER, CLEAR_FILTER_DOM_VALUES, SAVE_FILTER_DOM_VALUES } from "../constant";

const initState = {
  filters: {},
  filterDOMValues: {}
}

const filterReducer = (state = initState, action) => {
  switch (action.type) {
    case APPLY_FILTER:
      return {
        ...state,
        filters: action.payload
      }

    case CLEAR_FILTER:
      return {
        ...state,
        filters: {}
      }
    case SAVE_FILTER_DOM_VALUES:
      return {
        ...state,
        filterDOMValues: action.payload
      }

    case CLEAR_FILTER_DOM_VALUES:
      return {
        ...state,
        filterDOMValues: {}
      }

    default:
      return state;
  }
}
export default filterReducer