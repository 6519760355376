import React, { useState,useEffect} from 'react';

import AboutUs from './AboutUs';
import OurCollection from './Collection';
import Loader from '../common/Loader';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { add_to_cart } from '../../../store/actions/cart.action';
import { AuthService } from '../../../services/auth-service';



const Home =(props)=>{
    const [template_status,setTemplete_status]=useState(0)
    const [loading,setLoading]=useState(true)
    
    const cartinfo=useSelector(state=>state.cartdata.cart)
    const dispatch=useDispatch()

    useEffect(()=>{
        //let querystring = require('querystring')
        //let url         = `${window.config.api_url}/get-template-details`
        //let data        = querystring.stringify({ vendor_id: window.config.vendor_id })
        let formdata=new FormData()
        formdata.append("vendor_id",window.config.vendor_id)

         AuthService.header(formdata)
        .then(res => {
            if(res.data.success) {
                if(res.data.is_website_completed === 1) {
                    localStorage.setItem('logo', res.data.logo);
                    localStorage.setItem('hero_bg', res.data.hero_bg);
                    localStorage.setItem('about_bg', res.data.about_bg);
    
                    localStorage.setItem('selection_stone_1_img', res.data.selection_stone_1_img);
                    localStorage.setItem('selection_stone_3_img', res.data.selection_stone_3_img);
                    localStorage.setItem('selection_stone_2_img', res.data.selection_stone_2_img);
                    localStorage.setItem('template_status', res.data.is_website_completed);
                   
                    setLoading(false)
                }
                else {
                    localStorage.setItem('template_status', res.data.is_website_completed);
                    window.location = '/login';
                }
            }
        })
        
        //axios.post( url, data )
       
        .catch((err) => console.log(err))

        const localcart=JSON.parse(localStorage.getItem('cart') === null ? localStorage.getItem('dataCart'):localStorage.getItem('cart'))
       
        if(localcart !==null && cartinfo !==null ){
            if(localcart.length !==0 && cartinfo.length === 0) {
                
                
                for(let i=0;i<localcart.length ;i++){
                    
                   
                   dispatch(add_to_cart(localcart[i]))
                }
            }
            
        }
    

    },[]) 
       
    return(
        <div 
        className={`mainWrapper ${loading ? "noData" : ""}`} 
        id={loading ? "" : "home"}
    >
        {
        loading 
            ? <Loader />
            : <>
                <section 
                    className="align-items-end banner bg-cover d-flex home-banner fffff" 
                    style={{backgroundImage: `url(${localStorage.getItem('hero_bg')})`}} 
                    id="home"
                />  
                
                <OurCollection  />
                <AboutUs />
            </> 

        }
    </div>


    )
}
export default Home



