import axios from "axios";
import { toast } from "react-toastify";

const url=window.location.pathname
var id = url.substring(url.lastIndexOf('/') + 1);

const api = axios.create({
    baseURL:window.config.api_url,
    headers: {
        'Authorization': `Bearer ${localStorage['token'] ? localStorage.getItem('token') : id }`
    }
})

api.interceptors.response.use((res) => {
    return res
}, (err) => {
    toast.error(err.response.data.message,{
        className: 'danger-toast',
        draggable: false,
        position: toast.POSITION.TOP_RIGHT
    })
})

export default api