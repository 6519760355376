
export const setloadingfalse=()=>{
    return (dispatch)=>{
        dispatch({
        type:"LOADING_FALSE",
        

    })}
}

export const setloadingtrue=()=>{
    return (dispatch)=>{
        dispatch({
            type:"LOADING_TRUE",
            
        })
    }
}
