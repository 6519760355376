import React,{useEffect} from 'react';
import { useLocation } from 'react-router-dom';
// import '../../../css/theme.css';


const AboutUs = () => {
  const location = useLocation()

    const mystyle = {
        backgroundImage: "url("+localStorage.getItem('about_bg')+")"
    };

    useEffect(()=> {
      if (location.hash) {
          let elem = document.getElementById(location.hash.slice(1))
          if (elem) {
              elem.scrollIntoView({behavior: "smooth"})
          }
      } else {
      window.scrollTo({top:0,left:0, behavior: "smooth"})
      }
}, [location])

    
    return(
        <>
            {/* About us */}
            <section className="about-us-section text-center" id={'about'}>

                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-uppercase mb-5">ABOUT US</h2>
                        </div>
                    </div>
                </div>
                
                <div className="bg-cover d-flex align-items-center py-5" 
                    style={mystyle}>
                </div>
                    
            </section>

        </>
    )
}

export default AboutUs;