import { ProductService } from "../../services/products-service"
import { FETCH_ALL_PRODUCTS, SORT_ALL_PRODUCTS } from "../constant"

export const fetchallproducts = (filters = null, pageNumber = 1) => dispatch => {

  dispatch({ type: "LOADING_TRUE" })
  ProductService.completedProducts(pageNumber, filters)
    .then(res => {

      dispatch({
        type: FETCH_ALL_PRODUCTS,
        payload: res.data
      })
      dispatch({ type: "LOADING_FALSE" })

    })

}

export const sortProductsByAscPrice = (products) => dispatch => {
  dispatch({ type: "LOADING_TRUE" })

  dispatch({
    type: SORT_ALL_PRODUCTS,
    payload: products.sort(
      (a, b) =>
        parseInt(a.custome_price_total) - parseInt(b.custome_price_total)
    )
  })

  dispatch({ type: "LOADING_FALSE" })
}

export const sortProductsByDescPrice = (products) => dispatch => {
  dispatch({ type: "LOADING_TRUE" })

  dispatch({
    type: SORT_ALL_PRODUCTS,
    payload: products.sort(
      (a, b) =>
        parseInt(b.custome_price_total) - parseInt(a.custome_price_total)
    )
  })

  dispatch({ type: "LOADING_FALSE" })
}

export const sortProductsByAscWeight = (products) => dispatch => {
  dispatch({ type: "LOADING_TRUE" })

  dispatch({
    type: SORT_ALL_PRODUCTS,
    payload: products.sort(
      (a, b) =>
        parseInt(a.weight) - parseInt(b.weight)
    )
  })

  dispatch({ type: "LOADING_FALSE" })
}

export const sortProductsByDescWeight = (products) => dispatch => {
  dispatch({ type: "LOADING_TRUE" })

  dispatch({
    type: SORT_ALL_PRODUCTS,
    payload: products.sort(
      (a, b) =>
        parseInt(b.weight) - parseInt(a.weight)
    )
  })

  dispatch({ type: "LOADING_FALSE" })
}

export const incrementData = () => {
  return (dispatch) => {
    dispatch({ type: "INCREMENT" })
  }
}
