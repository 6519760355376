import swal from 'sweetalert';
import { ADD_TO_CART,REMOVE_FROM_CART} from "../constant"
import {toast} from 'react-toastify'


export const add_to_cart= (item)=>  {
  return (dispatch)=>{
  
      dispatch({
          type:ADD_TO_CART,
          payload:item
        })
        // toast('Product added to cart Successfully',{
        //     className: 'success-toast',
        //     draggable: false,
        //     position: toast.POSITION.TOP_RIGHT
        // })
    }
}

export const remove_from_cart=(id)=>{
    return (dispatch)=>{
        swal({
            title: "Are you sure?",
            text: "You want to remove this product?",
            icon: "warning",
            dangerMode: true,
            buttons: true
          }).then(willDelete=>{
            if(willDelete){

                dispatch({
                    type:REMOVE_FROM_CART,
                    payload:id
                })
                dispatch({type:"DECREMENT"})
                toast('Product deleted Successfully !!!',{
                    className: 'success-toast',
                    draggable: false,
                    position: toast.POSITION.TOP_RIGHT
                })
            }  
          })
         
    }
   
}
export const getCardData=()=>{
    return (dispatch)=>{
        dispatch({
            type:"GET_CART_"
        })
    }
}

export const clearCart=()=>{
  return (dispatch)=>{
    dispatch({
      type:"CLEAR_CART"
    })
  }
}
    
       