import React from 'react'

export default function Footer() {
    return (
        <footer className="footer">
           <div className="w-100"> <a href="https://www.thegemcloud.com/" className='pull-right text-dark' target='_blank' rel="noopener noreferrer"><p className="pull-right"><span>Powered By </span>
            <span className="footer-spon">GEMCLOUD</span></p></a></div>
        </footer>
    )
}
