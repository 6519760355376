const initState={
    loading:false,
    
}

const globalReducer=(state=initState,action)=>{
    switch(action.type){
        case "LOADING_FALSE":
            return {
                loading:false
            }
        case "LOADING_TRUE":
                return {
                    loading:true
                }
        default:
            return state;
    }
}
export default globalReducer;